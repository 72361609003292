import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import Textlink from 'ui-kit/textlink';
import { Highlight, P, Headline } from 'ui-kit/typography';

import Layout from '../components/layout';

const DatenschutzPage = ({ data }) => {
    const stageImage = data.stageImage.childImageSharp;
    return (
        <Layout description="Datenschutz">
            <Stage image={stageImage} headline={{ text: 'Datenschutz', level: 'h1' }} bottomShade />
            <Section bg="white" container="l">
                <Headline level="h2">1. Datenschutzerklärung</Headline>
                <P gap="xxl">
                    Wir freuen uns über Ihren Besuch auf unseren Webseiten. Nachfolgend möchten wir
                    Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutzgrundverordnung
                    (DSGVO) informieren.
                </P>
                <Headline level="h3">Verantwortlicher</Headline>
                <P gap="xxl">
                    <Highlight>Südbayerische Fleischwaren GmbH </Highlight> <br />
                    Robert-Bosch-Straße 13 <br />
                    85053 Ingolstadt{' '}
                </P>
                <P gap="xxl">
                    Tel.:{' '}
                    <Textlink
                        to="tel:+49841966340"
                        textColor="black"
                        gap="m"
                        display="inline-block"
                    >
                        +49 (0) 841 / 96 634 - 0
                    </Textlink>{' '}
                    <br />
                    Fax.:{' '}
                    <Textlink
                        to="fax:+498419663419"
                        textColor="black"
                        gap="m"
                        display="inline-block"
                    >
                        +49 (0) 841 / 96 634 - 19
                    </Textlink>{' '}
                    <br />
                    E-Mail:{' '}
                    <Textlink
                        to="email:info@suedbayerische-fleischwaren.de"
                        gap="m"
                        display="inline-block"
                    >
                        info@suedbayerische-fleischwaren.de
                    </Textlink>
                </P>
                <P gap="xxl">
                    <Highlight>Vertretungsberechtigte Geschäftsführer </Highlight>
                    <br />
                    Werner Gruber <br />
                    Bernhard Stadlmeier
                </P>
                <P gap="xxl">
                    <Highlight>Eingetragen bei</Highlight>
                    <br />
                    Handelsregister des Amtsgerichtes Ingolstadt / HRB 272
                </P>
                <P gap="xxl">
                    <Highlight>Umsatzsteuer-ID-Nr. gem. § 27a UStG</Highlight>
                    <br />
                    DE 811 118 299
                </P>
                <P gap="xxl">
                    <Highlight>Inhaltlich Verantwortlicher </Highlight>
                    <br />
                    Werner Gruber <br />
                    Jürgen Absmeier
                    <br />
                    Johann Baumer
                </P>
                <Headline level="h3">Speicherung der IP-Adresse</Headline>
                <P gap="xxl">
                    Wir speichern die von Ihrem Webbrowser übermittelte IP-Adresse streng
                    zweckgebunden für die Dauer von sieben Tagen, in dem Interesse, Angriffe auf
                    unsere Webseiten erkennen, eingrenzen und beseitigen zu können. Nach Ablauf
                    dieser Zeitspanne löschen bzw. anonymisieren wir die IP-Adresse. Rechtsgrundlage
                    ist Art. 6 Abs. 1 lit. f DSGVO.
                </P>
                <Headline level="h3">Nutzungsdaten</Headline>
                <P gap="xs">
                    Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär
                    sogenannte Nutzungsdaten zu statistischen Zwecken als Protokoll gespeichert, um
                    die Qualität unserer Webseiten zu verbessern. Dieser Datensatz besteht aus:
                </P>
                <P gap="xl">
                    <br />
                    der Seite, von der aus die Datei angefordert wurde,
                    <br />
                    dem Namen der Datei,
                    <br />
                    dem Datum und der Uhrzeit der Abfrage,
                    <br />
                    der übertragenen Datenmenge,
                    <br />
                    dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden),
                    <br />
                    der Beschreibung des Typs des verwendeten Webbrowsers,
                    <br />
                    der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein
                    Personenbezug nicht mehr herstellbar ist.
                </P>
                <P gap="xxl">Die genannten Protokolldaten werden nur anonymisiert gespeichert.</P>
                <Headline level="h2">2. Datenübermittlung an Dritte</Headline>
                <Headline level="h3">Datenübermittlung an Dritte</Headline>
                <P gap="xxl">Wir übermitteln Ihre personenbezogenen Daten nicht an Dritte.</P>
                <Headline level="h2">3. Cookies</Headline>
                <P gap="l">
                    Auf unseren Webseiten nutzen wir Cookies. Cookies sind kleine Textdateien, die
                    auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet
                    zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser
                    schließen und permanenten Cookies, die über die einzelne Sitzung hinaus
                    gespeichert werden. Cookies können Daten enthalten, die eine Wiedererkennung des
                    genutzten Geräts möglich machen. Teilweise enthalten Cookies aber auch lediglich
                    Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar sind.
                </P>
                <P gap="l">
                    Wir nutzen auf unseren Webseiten Session-Cookies und permanente Cookies. Die
                    Verarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO und in dem
                    Interesse die Benutzerführung zu optimieren bzw. zu ermöglichen und die
                    Darstellung unserer Webseite anzupassen.
                </P>
                <P gap="xxl">
                    Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von
                    Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie
                    können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen
                    und das Setzen neuer Cookies verhindern. Bitte beachten Sie, dass unsere
                    Webseiten dann ggf. nicht optimal angezeigt werden und einige Funktionen
                    technisch nicht mehr zur Verfügung stehen.
                </P>
                <Headline level="h2">4. Trackingtools</Headline>
                <Headline level="h3">Matomo</Headline>
                <P gap="l">
                    Zur bedarfsgerechten Gestaltung unserer Website erstellen wir pseudonyme
                    Nutzungsprofile mit Hilfe des Webanalysetools Matomo. Matomo verwendet Cookies,
                    die auf Ihrem Endgerät gespeichert und von uns ausgelesen werden können. Auf
                    diese Weise sind wir in der Lage, wiederkehrende Besucher zu erkennen und als
                    solche zu zählen. Eine Speicherung der ungekürzten IP-Adresse erfolgt nicht. Die
                    Datenverarbeitung erfolgt auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO bzw.
                    § 15 Abs. 3 TMG und in dem Interesse zu erfahren, wie häufig unsere Webseiten
                    von unterschiedlichen Nutzern aufgerufen wurden.
                </P>
                <P gap="xxl">
                    Sie können der Verarbeitung jederzeit widersprechen. Klicken Sie dazu bitte das
                    folgende Kästchen an. In diesem Fall wird in Ihrem Browser ein sog.
                    Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten
                    erhebt. Wenn Sie Ihre Cookies löschen, hat dies allerdings zur Folge, dass auch
                    das Opt-Out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden
                    muss.
                </P>
                <Headline level="h2">5. Social Media Plugins</Headline>
                <P gap="l">
                    Aus Gründen des Datenschutzes binden wir keine Social Plugins direkt in unseren
                    Webauftritt ein. Wenn Sie unsere Seiten aufrufen, werden daher keine Daten an
                    Social Media-Dienste, wie etwa Facebook, Twitter, XING oder Google+ übermittelt.
                    Eine Profilbildung durch Dritte ist somit ausgeschlossen.
                </P>
                <P gap="l">
                    Sie haben dennoch die Möglichkeit, ausgewählte Seiten mit einem Klick auf
                    Facebook, Twitter, XING oder Google+ Schaltflächen zu teilen und sehen zudem
                    bereits bei Aufruf der Beiträge, wie oft diese in der Vergangenheit geteilt
                    wurden. Wir nutzen hierzu die sog. Shariff-Lösung, die vom c’t Magazin
                    entwickelt wurde, um eine datenschutzkonforme Alternative zu den klassischen
                    Social-Plugins zu bieten.
                </P>
                <P gap="xxl">
                    Was steckt dahinter? Die Shariff-Lösung führt dazu, dass in einem ersten Schritt
                    alle Daten und Funktionen, die zur Darstellung der Facebook, Twitter, XING oder
                    Google+ Schaltflächen erforderlich sind, von unserem Webserver bereitgestellt
                    werden. Erst wenn Sie sich entschließen, einen Beitrag über die entsprechende
                    Schaltfläche zu teilen und diese anklicken, erfolgt eine Datenübertragung an den
                    Betreiber des jeweiligen Social Media Dienstes.
                </P>
                <Headline level="h2">6. Erläuterungen der Sicherheitsmaßnahmen</Headline>
                <Headline level="h3">Datensicherheit</Headline>
                <P gap="xxl">
                    Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen,
                    treffen wir technische und organisatorische Maßnahmen. Wir setzen auf unseren
                    Seiten ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner
                    zu unserem Server und umgekehrt über das Internet mittels einer
                    TLS-Verschlüsselung übertragen. Sie erkennen dies daran, dass in der
                    Statusleiste Ihres Browsers das Schloss-Symbol geschlossen ist und die
                    Adresszeile mit https:// beginnt.
                </P>
                <Headline level="h2">7. Rechte des Nutzers</Headline>
                <Headline level="h3">Ihre Rechte als Nutzer</Headline>
                <P gap="l">
                    Bei Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als
                    Webseitennutzer bestimmte Rechte:
                </P>
                <Headline level="h3">1. Auskunftsrecht (Art. 15 DSGVO):</Headline>
                <P gap="l">
                    Sie haben das Recht eine Bestätigung darüber zu verlangen, ob sie betreffende
                    personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein
                    Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15
                    DSGVO im einzelnen aufgeführten Informationen.
                </P>
                <Headline level="h3">
                    Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO):
                </Headline>
                <P gap="l">
                    Sie haben das Recht, unverzüglich die Berichtigung sie betreffender unrichtiger
                    personenbezogener Daten und ggf. die Vervollständigung unvollständiger
                    personenbezogener Daten zu verlangen.
                </P>
                <P gap="l">
                    Sie haben zudem das Recht, zu verlangen, dass sie betreffende personenbezogene
                    Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im
                    einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten
                    Zwecke nicht mehr benötigt werden.
                </P>
                <Headline level="h3">
                    3. Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO):
                </Headline>
                <P gap="l">
                    Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine
                    der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie
                    Widerspruch gegen die Verarbeitung eingelegt haben, für die Dau-er einer
                    etwaigen Prüfung.
                </P>
                <Headline level="h3">4. Recht auf Datenübertragbarkeit (Art. 20 DSGVO): </Headline>
                <P gap="l">
                    Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine
                    der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie
                    Widerspruch gegen die Verarbeitung eingelegt haben, für die Dau-er einer
                    etwaigen Prüfung.
                </P>
                <Headline level="h3">Widerspruchsrecht (Art. 21 DSGVO):</Headline>
                <P gap="l">
                    Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung
                    zur Wahrung berechtigter Interessen), steht Ihnen das Recht zu, aus Gründen, die
                    sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
                    Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht
                    mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die
                    Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen
                    Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen.
                </P>
                <Headline level="h3">Beschwerderecht bei einer Aufsichtsbehörde</Headline>
                <P gap="xxl">
                    Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer
                    Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
                    betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das
                    Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem
                    Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
                    mutmaßlichen Verstoßes geltend gemacht werden.
                </P>
                <Headline level="h2">8. Kontaktdaten Datenschutzbeauftragter</Headline>
                <P gap="l">
                    Unser betrieblicher Datenschutzbeauftragter steht Ihnen gerne für Auskünfte oder
                    Anregungen zum Thema Datenschutz zur Verfügung:
                </P>
                <P gap="l">
                    EDEKA Südbayern Handelsgesellschaft mbH
                    <br />
                    Datenschutzbeauftragter
                    <br />
                    Ingolstädter Straße 120
                    <br />
                    85080 Gaimersheim
                </P>
                <P gap="l">
                    Tel. 08458 / 62 0
                    <br />
                    datenschutz.suedbayern@edeka.de
                </P>
            </Section>
        </Layout>
    );
};

DatenschutzPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "imprint-and-legal-pages/buehne-impressum-und-datenschutz.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default DatenschutzPage;
